import Repository from '../../repositories/RepositoryFactory';

const MedicalTeamRepository = Repository.get('medicalteam');

export default {
	namespaced: true,
	state: {
		medicalTeams: [],
		selectedMedicalTeam: null,
	},
	mutations: {
		LOAD_MEDICAL_TEAMS (state, payload) {
			state.medicalTeams = payload.medicalTeamsData;
		},
		SELECTED_MEDICAL_TEAM (state, payload) {
			state.selectedMedicalTeam = payload;
		},
	},
	actions: {
		// eslint-disable-next-line no-unused-vars
		async createMedicalTeam ({ commit }, body) {
			const res = await MedicalTeamRepository.createMedicalTeam(body);
			return res;
		},
		// eslint-disable-next-line no-unused-vars
		async assignMemberToMedicalTeam ({ commit }, body) {
			const res = await MedicalTeamRepository.assignMemberToMedicalTeam(body);
			return res;
		},
		// eslint-disable-next-line no-unused-vars
		async deassignMemberToMedicalTeam ({ commit }, body) {
			const res = await MedicalTeamRepository.deassignMemberToMedicalTeam(body);
			return res;
		},
		async assignMultipleMembersToMedicalTeam ({ commit }, body) {
			// {
			// 	"MedicalTeamId":687,
			// 	"MemberUserIdList":[1056,1058]
			// }
			const res = await MedicalTeamRepository.assignMultipleMembersToMedicalTeam(body);
			return res;
		},
		async deassignMultipleMembersFromMedicalTeam ({ commit }, body) {
			// {
			// 	"MedicalTeamId":687,
			// 	"MemberUserIdList":[1056,1058]
			// }
			const res = await MedicalTeamRepository.deassignMultipleMembersFromMedicalTeam(body);
			return res;
		},
		// eslint-disable-next-line no-unused-vars
		async editMedicalTeam ({ commit }, body) {
			const res = await MedicalTeamRepository.editMedicalTeam(body);
			return res;
		},
		// eslint-disable-next-line no-unused-vars
		async deleteMedicalTeam ({ commit }, body) {
			const res = await MedicalTeamRepository.deleteMedicalTeam(body);
			return res;
		},
		// eslint-disable-next-line no-unused-vars
		async getMedicalTeamForPatient ({ commit }, id) {
			const res = await MedicalTeamRepository.getMedicalTeamForPatient(id);
			return res;
		},
		async getMedicalTeamForDoctor ({ commit }, headersParams) {
			const res = await MedicalTeamRepository.getMedicalTeamForDoctor(headersParams.doctorId, headersParams.hospitalId);
			commit('LOAD_MEDICAL_TEAMS', res);
			return res;
		},
		async getMedicalTeamForNurse ({ commit }, headersParams) {
			const res = await MedicalTeamRepository.getMedicalTeamForNurse(headersParams.nurseId, headersParams.hospitalId);
			commit('LOAD_MEDICAL_TEAMS', res);
			return res;
		},
		async getMedicalTeamForHospitalAdmin ({ commit }, id) {
			const res = await MedicalTeamRepository.getMedicalTeamForHospitalAdmin(id);
			commit('LOAD_MEDICAL_TEAMS', res);
			return res;
		},
	},
	getters: {},
};
