import Vue from 'vue';
import App from './App.vue';
import i18n from './i18n';
import './scss/main.scss';
import './plugins/axios';
import vuetify from './plugins/vuetify';
import store from './data/store';
import router from './router';
import VueCookies from 'vue-cookies';
// import * as Sentry from '@sentry/vue';
// import { BrowserTracing } from '@sentry/tracing';
import material from 'vue-material';
import { formatterFilter, addTime } from '../src/utils/luxon-formater';
import './plugins/vee-validate';
import VueGoogleCharts from 'vue-google-charts';

Vue.use(VueCookies);
Vue.use(VueGoogleCharts);
Vue.filter('dateformat', formatterFilter);
Vue.filter('addTime', addTime);
Vue.config.productionTip = false;

// Sentry.init({
// 	Vue,
// 	dsn: 'https://666dbb65a70f4b3a8c6da5a50d1a5089@o1174558.ingest.sentry.io/6270623',
// 	integrations: [
// 		new BrowserTracing({
// 			routingInstrumentation: Sentry.vueRouterInstrumentation(router),
// 			tracingOrigins: ['devapp.vitalcheckups.com', 'testapp.vitalcheckups.com'],
// 		}),
// 	],
// 	// Set tracesSampleRate to 1.0 to capture 100%
// 	// of transactions for performance monitoring.
// 	// We recommend adjusting this value in production
// 	tracesSampleRate: process.env.VUE_APP_NODE_ENV === 'production' ? 0.5 : 0,
// });

new Vue({
	vuetify,
	store,
	router,
	material,
	i18n,
	render: h => h(App),
}).$mount('#app');
