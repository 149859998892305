import Repository from '../../repositories/RepositoryFactory';

const AppointmentsRepository = Repository.get('appointments');

export default {
	namespaced: true,
	state: {
		appointments: null,
		appointmentsForToday: null,
		appointmentsForDoctor: [],
		notes: null,
	},
	mutations: {
		GET_APPOINTMENTS (state, payload) {
			state.appointments = payload.data;
		},
		GET_APPOINTMENTS_FOR_TODAY (state, payload) {
			state.appointmentsForToday = payload.data;
		},
		GET_APPOINTMENTS_FOR_DOCTOR (state, payload) {
			state.appointmentsForToday = payload.data;
		},
		CREATE_APPOINTMENTS (state, payload) {
			var today = new Date().toISOString();
			payload.data.appointmentDateTime = payload.data.appointmentDateTime.split('Z')[0];
			if (payload.data.appointmentDateTime.split('T')[0] === today.split('T')[0]) {
				state.appointmentsForToday.unshift(payload.data);
			}
		},
		ADD_NOTE () {

		},
		GET_NOTES (state, payload) {
			state.notes = payload.data;
		},
	},
	actions: {
		async getAppointments ({ commit }) {
			commit('GET_APPOINTMENTS', await AppointmentsRepository.getAppointments());
		},
		async getAppointmentsForToday ({ commit }, id) {
			commit('GET_APPOINTMENTS_FOR_TODAY', await AppointmentsRepository.getAppointmentsForToday(id));
		},
		async getAppointmentsForDoctor ({ commit }, id) {
			const response = await AppointmentsRepository.getAppointmentsForDoctor(id);
			return response;
		},
		async getAppointmentsByHospitalId ({ commit }, id) {
			commit('GET_APPOINTMENTS_FOR_TODAY', await AppointmentsRepository.getAppointmentsByHospitalId(id));
		},
		// eslint-disable-next-line no-unused-vars
		async createAppointment ({ commit }, body) {
			commit('CREATE_APPOINTMENTS', await AppointmentsRepository.createAppointment(body));
		},
		async newAppointment ({ commit }, body) {
			commit('CREATE_APPOINTMENTS', await AppointmentsRepository.newAppointment(body));
		},
		async addAppointmentNote ({ commit }, body) {
			commit('ADD_NOTE', await AppointmentsRepository.addAppointmentNote(body));
		},
		async getNotesWithPatient ({ commit }, patientId) {
			commit('GET_NOTES', await AppointmentsRepository.getNotesWithPatient(patientId));
		},
		async startAppointment ({ commit }, body) {
			const res = await AppointmentsRepository.startAppointment(body);
			return res;
		},
		async updateAppointments ({ commit }, body) {
			const res = await AppointmentsRepository.updateAppointments(body);
			return res;
		},
		async stopAppointment ({ commit }, body) {
			const res = await AppointmentsRepository.stopAppointment(body);
			return res;
		},
		async addAttendeeForAppointment ({ commit }, body) {
			const res = await AppointmentsRepository.addAttendeeForAppointment(body);
			return res;
		},
	},
	getters: {},
};
