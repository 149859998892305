import Repository from '../../repositories/RepositoryFactory';

const RolesRepository = Repository.get('roles');

export default {
	namespaced: true,
	state: {
		roles: null,
	},
	mutations: {
		GET_ROLES (state, payload) {
			state.roles = payload.data;
		},
	},
	actions: {
		async getRoles ({ commit }) {
			commit('GET_ROLES', await RolesRepository.getRoles());
		},
	},
	getters: {},
};
