module.exports = class DoctorMetaData {
	constructor (object) {
		this.hospitalsAssignedData = object.hospitalsAssignedData;
		this.id = object.id;
		this.licenceId = object.licenceId;
		this.metadataInfo = object.metadataInfo;
		this.spetializationData = object.spetializationData;
		this.userId = object.userId;
	}
};
