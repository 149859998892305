/* eslint-disable no-unused-vars */
import Repository from '../../repositories/RepositoryFactory';

const MentalHealthRepository = Repository.get('mentalhealth');

export default {
	namespaced: true,
	state: {
		surveyTemplates: null,
		surveyTemplate: {},
		surveys: null,
		survey: {},
		surveyPatientDetails: {},
		surveyPatientReports: {},
		surveyAssignedById: {},
		recurrence: [],
		surveyRecurrenceId: undefined,
	},
	mutations: {
		GET_SURVEY_TEMPLATES (state, payload) {
			// state.surveys = payload.data;
			state.surveyTemplates = payload.data;
		},
		GET_SURVEY_TEMPLATE_BY_ID (state, payload) {
			// state.survey = payload.data;
			state.surveyTemplate = payload.data;
		},
		GET_SURVEY_FOR_PATIENT (state, payload) {
			state.surveyPatientDetails = payload.data;
		},
		GET_SURVEY_REPORTS_FOR_PATIENT (state, payload) {
			state.surveyPatientReports = payload.data;
		},
		GET_SURVEY_ASSIGNED_BY_ID (state, payload) {
			state.surveyAssignedById = payload.data;
		},
		GET_SURVEY_RECURRENCE (state, payload) {
			state.recurrence = payload.data;
		},
		UPDATE_FILTERS_RECURRENCE: (state, payload) => {
			state.surveyRecurrenceId = payload;
		},
	},
	actions: {
		async updateFiltersRecurrence ({ commit }, payload) {
			commit('UPDATE_FILTERS_RECURRENCE', payload);
		},
		async assigneMentalHealth ({ commit }, obj) {
			await MentalHealthRepository.assigneMentalHealth(obj);
		},
		async assigneMentalHealthEdit ({ commit }, obj) {
			await MentalHealthRepository.assigneMentalHealthEdit(obj);
		},
		async stofferAnswerReorder ({ commit }, obj) {
			await MentalHealthRepository.stofferAnswerReorder(obj);
		},
		async stquestionReorder ({ commit }, obj) {
			await MentalHealthRepository.stquestionReorder(obj);
		},
		async getMentalHealthSurveyTemplates ({ commit }, hospitalId) {
			commit('GET_SURVEY_TEMPLATES', await MentalHealthRepository.getMentalHealthSurveyTemplates(hospitalId));
		},
		async getSurveyTemplateById ({ commit }, id) {
			commit('GET_SURVEY_TEMPLATE_BY_ID', await MentalHealthRepository.getSurveyTemplateById(id));
		},
		async deleteSurveysById ({ commit }, id) {
			return await MentalHealthRepository.deleteSurveysById(id);
		},
		async deleteSurveyTemplateByIdV2 ({ commit }, id) {
			return await MentalHealthRepository.deleteSurveyTemplateByIdV2(id);
		},
		async editSurvey ({ commit }, id) {
			return await MentalHealthRepository.editSurvey(id);
		},
		async createSurveyTemplate ({ commit }, obj) {
			return await MentalHealthRepository.createSurveyTemplate(obj);
		},
		async createQuestion ({ commit }, obj) {
			return await MentalHealthRepository.createQuestion(obj);
		},
		async updateAnswer ({ commit }, obj) {
			return await MentalHealthRepository.updateAnswer(obj);
		},
		async deleteAnswer ({ commit }, obj) {
			return await MentalHealthRepository.deleteAnswer(obj);
		},
		async createAnswer ({ commit }, obj) {
			return await MentalHealthRepository.createAnswer(obj);
		},
		async updateQuestion ({ commit }, obj) {
			return await MentalHealthRepository.updateQuestion(obj);
		},
		async deleteQuestion ({ commit }, id) {
			await MentalHealthRepository.deleteQuestion(id);
		},
		async getSurveyForPatient ({ commit }, obj) {
			commit('GET_SURVEY_FOR_PATIENT', await MentalHealthRepository.getSurveyForPatient(obj));
		},
		async getSurveyReportsForPatient ({ commit }, obj) {
			commit('GET_SURVEY_REPORTS_FOR_PATIENT', await MentalHealthRepository.getSurveyReportsForPatient(obj));
		},
		async getSurveyRecurrence ({ commit }, obj) {
			commit('GET_SURVEY_RECURRENCE', await MentalHealthRepository.getSurveyRecurrence(obj));
		},
		async SurveysAssignedById ({ commit }, obj) {
			commit('GET_SURVEY_ASSIGNED_BY_ID', await MentalHealthRepository.SurveysAssignedById(obj));
		},
	},
	getters: {},
};
