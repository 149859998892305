import { UsersError } from '@/lib/Errors';
import _axios from '@/plugins/axios';
export default {
	async assigneMentalHealth (obj) {
		const response = await _axios.post('/survey/assignsurveytemplatetomultiplepatients', obj.body, { headers: { PatientsIdsList: obj.header } });
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async assigneMentalHealthEdit (obj) {
		const response = await _axios.put(`/survey/${obj.header}`, obj.body);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},

	async stofferAnswerReorder (obj) {
		const response = await _axios.put('/stofferedanswer/reorder', obj);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async stquestionReorder (obj) {
		const response = await _axios.put('/stquestion/reorder', obj);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async getMentalHealthSurveyTemplates (hospitalId) {
		const headers = { headers: { hospitalId: hospitalId } };
		const response = await _axios.get('/surveytemplate', headers);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async getSurveyTemplateById (id) {
		const response = await _axios.get(`/surveytemplate/${id}`);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async editSurvey (body) {
		const response = await _axios.put(`/surveytemplate/${body.id}`, body);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async createSurveyTemplate (obj) {
		const response = await _axios.post('/surveytemplate', obj);
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new UsersError('No meta data!');
	},
	async deleteSurveysById (id) {
		const response = await _axios.delete(`/surveytemplate/${id}`);
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new UsersError('No meta data!');
	},
	async deleteSurveyTemplateByIdV2 (id) {
		try {
			const response = await _axios.delete(`/surveytemplate/${id}`);
			if (response.data.resFlag) {
				return response.data;
			} else {
				return response.data;
			}
		} catch (error) {
			if (error.response && error.response.status >= 400 && error.response && error.response.status < 500) {
				return error.response.data;
			} else {
				// Handle other errors >=500
				// You can handle other types of errors here.
				throw error;
			}
		}
	},
	async createQuestion (obj) {
		const response = await _axios.post('/stquestion', obj);
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new UsersError('No meta data!');
	},
	async updateAnswer (obj) {
		const response = await _axios.put(`/stofferedanswer/${obj.id}`, obj);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async deleteAnswer (id) {
		const response = await _axios.delete(`/stofferedanswer/${id}`);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async createAnswer (obj) {
		const response = await _axios.post('/stofferedanswer', obj);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async updateQuestion (obj) {
		const response = await _axios.put(`/stquestion/${obj.id}`, obj);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async deleteQuestion (id) {
		const response = await _axios.delete(`/stquestion/${id}`);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async getSurveyForPatient (id) {
		const response = await _axios.get('/survey/getassignedinprogresssurveysforpatient', { headers: { patientId: id } });
		if (response.data.resFlag) {
			return response.data;
		} else {
			throw new UsersError('Could not get data!');
		}
	},
	async getSurveyReportsForPatient (obj) {
		const response = await _axios.get('/survey/getsurveysreportforpatient', { headers: { patientId: obj.PatientId, FilterCase: obj.FilterCase, LimitForOccurrences: obj.LimitForOccurrences } });
		if (response.data.resFlag) {
			return response.data;
		} else {
			throw new UsersError('Could not get data!');
		}
	},
	async getSurveyRecurrence (obj) {
		const response = await _axios.get('/surveyrecurrence/getchilddata', { headers: { surveyRecurrenceId: obj } });
		if (response.data.resFlag) {
			return response.data;
		} else {
			throw new UsersError('Could not get data!');
		}
	},
	async SurveysAssignedById (id) {
		const response = await _axios.get(`/survey/${id}`);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
};
