module.exports = class Notification {
	constructor (object) {
		this.AdditionalData = object.additionalData;
		this.AppointmentId = object.appointmentId;
		this.DeviceType = object.deviceType;
		this.FromUserId = object.fromUserId;
		this.MessageToSend = object.notificationMsg;
		this.MessageToSendEsp = object.notificationMsgEsp;
		this.NotificationTimestamp = object.notificationTimestamp;
		this.NotificationTypeId = object.notificationTypeId;
		this.ReferenceData = object.referenceData;
		this.ToUserId = object.userId;
		this.surveyRecurrencId = object.surveyRecurrencId;
		this.IsGroupNotification = object.isGroupNotification;
		this.NotificationId = object.notificationId;
		this.IsRead = object.isRead;
		this.fromUserFullname = object.fromUserFullname;
		this.appointmentRequestId = object.appointmentRequestId;
		this.MedicalTeamData = object.medicalTeamData;
		this.MedicalTeamId = object.medicalTeamId;
		this.NId = object.nId;
		this.NotificationDirection = object.notificationDirection;
		this.NotificationSentToGroup = object.notificationSentToGroup;
		this.ProcessedBy = object.processedBy;
		this.ProcessedTimestamp = object.processedTimestamp;
		this.ProcessingStatus = object.processingStatus;
		this.RelatedToNotificationId = object.relatedToNotificationId;
		this.ResolutionComments = object.resolutionComments;
		this.UserFullname = object.userFullname;
		this.AttachedFile = object.attachedFile;
		this.AttachedFileExtension = object.attachedFileExtension;
		this.PatientRelatedId = object.patientRelatedId;
	}
};
