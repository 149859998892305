import Repository from '../../repositories/RepositoryFactory';

const PatientDiagnosisRepository = Repository.get('patientdiagnosis');

export default {
	namespaced: true,
	state: {
		patientDiagnosisList: null,
		selectedPatientDiagnosis: null,
	},
	mutations: {
		GET_PATIENT_DIAGNOSIS_LIST (state, payload) {
			state.patientDiagnosisList = payload;
		},
		GET_PATIENT_DIAGNOSIS_BY_ID (state, payload) {
			state.selectedPatientDiagnosis = payload;
		},
	},
	actions: {
		async getDiagnosisListForPatient ({ commit }, paramPatientId) {
			commit('GET_PATIENT_DIAGNOSIS_LIST', await PatientDiagnosisRepository.getDiagnosisListForPatient(paramPatientId));
		},
		async getPatientDiagnosisById ({ commit }, paramPatientDiagnosisId) {
			commit('GET_PATIENT_DIAGNOSIS_BY_ID', await PatientDiagnosisRepository.getUserById(paramPatientDiagnosisId));
		},
		async createPatientDiagnosis ({ commit }, body) {
			const res = await PatientDiagnosisRepository.createPatientDiagnosis(body);
			return res;
		},
		async editPatientDiagnosis ({ commit }, body) {
			const res = await PatientDiagnosisRepository.editPatientDiagnosis(body);
			return res;
		},
		async deletePatientDiagnosis ({ commit }, paramPatientDiagnosisId) {
			const res = await PatientDiagnosisRepository.deletePatientDiagnosis(paramPatientDiagnosisId);
			return res;
		},
	},
	getters: {
	},
};
