import { DateTime, Duration } from 'luxon';

const TIMESTAMP = Object.assign(DateTime.DATETIME_FULL_WITH_SECONDS, { month: 'short' });
const TIMESTAMP_MED = Object.assign(DateTime.DATE_FULL, { month: 'short' });

function getFormatFromString (format) {
	// NOTE: These are DIFFERENT from the ones in `formattedDateTime` because
	// several of those do not actually describe what they really output.
	switch (format) {
	case 'timestamp_med':
		return Object.assign(DateTime.DATETIME_SHORT);
	case 'date_med':
		return DateTime.DATE_MED;
	case 'date_huge':
		return DateTime.DATE_HUGE;
	case 'time':
		return DateTime.TIME_WITH_SHORT_OFFSET;
	case 'time_am_pm':
		return DateTime.TIME_SIMPLE;
	case 'time_simple':
		return DateTime.TIME_24_SIMPLE;
	case 'date':
		return DateTime.DATE_SHORT;
	case 'datetime_short':
		return DateTime.DATETIME_SHORT;
	case 'datetime_med':
		return DateTime.DATETIME_MED;
	case 'datetime_short_with_seconds':
		return DateTime.DATETIME_SHORT_WITH_SECONDS;
	default:
		return format;
	}
}

/**
 * @deprecated Use the `dateFormat` filter instead
 */
export function formattedDateTime (timestamp, type, locale, tz = null) {
	let format;
	if (type === 'timestamp') {
		format = TIMESTAMP;
	} else if (type === 'time') {
		format = DateTime.TIME_WITH_SHORT_OFFSET;
	} else if (type === 'date') {
		format = DateTime.DATE_SHORT;
	} else if (type === 'timestamp_med') {
		format = TIMESTAMP_MED;
	} else if (type === 'date_med') {
		format = DateTime.DATE_FULL;
	} else if (type === 'test') {
		format = DateTime.TIME_24_SIMPLE;
	} else if (type === 'time_am_pm') {
		format = DateTime.TIME_SIMPLE;
	}

	if (tz) {
		return DateTime.fromISO(timestamp, { zone: 'utc' }).setZone(tz).setLocale(locale).toLocaleString(format);
	} else {
		return DateTime.fromISO(timestamp, { zone: 'utc' }).toLocal().setLocale(locale).toLocaleString(format);
	}
}

/**
 * @deprecated Convert to ISO 8601 dates and then use the `dateFormat` filter instead
 */
export function formattedDateTimeFromSQL (timestamp, type, tz = null) {
	let format;

	if (type === 'timestamp') {
		format = TIMESTAMP;
	} else if (type === 'time') {
		format = DateTime.TIME_WITH_SHORT_OFFSET;
	} else if (type === 'date') {
		format = DateTime.DATE_SHORT;
	}

	if (tz) {
		return DateTime.fromSQL(timestamp, { zone: 'utc' }).setZone(tz).toLocaleString(format);
	} else {
		return DateTime.fromSQL(timestamp, { zone: 'utc' }).toLocal().toLocaleString(format);
	}
}

export function formatDuration (milliseconds) {
	return Duration.fromISOTime(milliseconds).as('minutes');
}

/**
 * @deprecated Use the `dateFormat` filter instead
 */
export function getNow () {
	return DateTime.now().toLocaleString(TIMESTAMP);
}

export function getNowSQL () {
	return DateTime.now().toSQL();
}

export function secondsToTimer (seconds) {
	const a = Math.abs(seconds);
	let time;
	if (seconds < 0) {
		time = Duration.fromMillis(a * 1000);
	} else {
		time = Duration.fromMillis(seconds * 1000);
	}

	return time.toFormat('hh:mm:ss');
}

export function addTime (timestamp, object) {
	return DateTime.fromISO(timestamp).plus(object);
}

export function differenceTimeStamps (start, end) {
	const startObj = DateTime.fromISO(start);
	const endObj = DateTime.fromISO(end);
	return endObj.diff(startObj).as('seconds');
}

// export function timeAgo (date) {
// 	if (!date) {
// 		return;
// 	}
// 	if (typeof date !== 'object') {
// 		date = new Date(date);
// 	}

// 	var seconds = Math.floor((new Date() - date) / 1000);
// 	var intervalType;

// 	var interval = Math.floor(seconds / 31536000);
// 	if (interval >= 1) {
// 		intervalType = 'year';
// 	} else {
// 		interval = Math.floor(seconds / 2592000);
// 		if (interval >= 1) {
// 			intervalType = 'month';
// 		} else {
// 			interval = Math.floor(seconds / 86400);
// 			if (interval >= 1) {
// 				intervalType = 'day';
// 			} else {
// 				interval = Math.floor(seconds / 3600);
// 				if (interval >= 1) {
// 					intervalType = 'hour';
// 				} else {
// 					interval = Math.floor(seconds / 60);
// 					if (interval >= 1) {
// 						intervalType = 'minute';
// 					} else {
// 						interval = seconds;
// 						intervalType = 'second';
// 					}
// 				}
// 			}
// 		}
// 	}
// 	if (interval > 1 || interval === 0) {
// 		intervalType += 's';
// 	}
// 	return interval + ' ' + intervalType + ' ago';
// }

export function phoneNumberFormatter (phoneNumberString) {
	var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
	var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		// eslint-disable-next-line no-unused-vars
		var intlCode = (match[1] ? '+1 ' : '');
		return true;
		// return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
	}
	return false;
}

export function formatterFilter (timestamp, format, locale, timezone, defaultIfNull = '') {
	if (!timestamp) {
		return defaultIfNull;
	}

	// var locale = 'en-US';

	format = getFormatFromString(format);

	if (DateTime.isDateTime(timestamp)) {
		return timestamp.setLocale(locale).toLocaleString(format);
	}

	if (timestamp instanceof Date) {
		if (timezone) {
			return DateTime.fromJSDate(timestamp, { zone: 'utc' }).setZone(timezone).setLocale(locale).toLocaleString(format);
		}
		return DateTime.fromJSDate(timestamp).setLocale(locale).toLocaleString(format);
	}

	if (typeof timestamp === 'string' || timestamp instanceof String) {
		if (timezone) {
			return DateTime.fromISO(timestamp, { zone: 'utc' }).setZone(timezone).setLocale(locale).toLocaleString(format);
		}
		return DateTime.fromISO(timestamp).setLocale(locale).toLocaleString(format);
	}

	if (Number.isFinite(timestamp)) {
		// TODO: Is Milliseconds better?
		return DateTime.fromSeconds(timestamp).setLocale(locale).toLocaleString(format);
	}

	return DateTime.invalid('invalid input').setLocale(locale).toLocaleString(format);
}
