import Repository from '../../repositories/RepositoryFactory';

const PatientMetaDataRepository = Repository.get('patientmetadata');

export default {
	namespaced: true,
	state: {
		metaData: null,
		editedData: null,
		diagnosisByCategory: [],
	},
	mutations: {
		GET_META_DATA (state, payload) {
			state.metaData = payload;
		},
		EDIT_META_DATA (state, payload) {
			state.editedData = payload;
		},
		GET_DIAGNOSIS_CATEGORY (state, payload) {
			state.diagnosisByCategory = payload;
		},
	},
	actions: {
		async getMetaData ({ commit }, id) {
			commit('GET_META_DATA', await PatientMetaDataRepository.getMetaData(id));
		},
		async createPatientMetaData ({ commit }, body) {
			const res = await PatientMetaDataRepository.createPatientMetaData(body);
			return res;
		},
		async editPatientMetaData ({ commit }, body) {
			const res = await PatientMetaDataRepository.editPatientMetaData(body);
			return res;
		},
		async getDiagnosesByCategory ({ commit }, body) {
			const res = await PatientMetaDataRepository.getDiagnosesByCategory(body);
			return res;
			// commit('GET_DIAGNOSIS_CATEGORY', await PatientMetaDataRepository.getDiagnosesByCategory(body));
		},
	},
	getters: {
		getDiagnoseCategory (state) {
			return state.diagnosisByCategory.data;
		},
	},
};
