import Repository from '../../repositories/RepositoryFactory';
const VerifiersRepository = Repository.get('verifiers');

export default {
	namespaced: true,
	state: {
		verifiers: null,
		timeZones: [],
		allverifires: null,
		surveyRecurrencesRule: null,
	},
	mutations: {
		GET_VERIFIERS (state, payload) {
			state.verifiers = payload;
		},
		GET_TIME_ZONES (state, payload) {
			state.timeZones = payload;
		},
		GET_ALL_VERIFIRES (state, payload) {
			state.allverifires = payload;
		},
		GET_VERIFIERS_SURVEY_RECURRENCE_RULES (state, payload) {
			state.surveyRecurrencesRule = payload;
		},
	},
	actions: {
		async getVerifiersForDomain ({ commit }, header) {
			commit('GET_VERIFIERS', await VerifiersRepository.getVerifiersForDomain(header));
		},
		async getVerifiersForMultipleDomains ({ commit }, header) {
			commit('GET_VERIFIERS', await VerifiersRepository.getVerifiersForMultipleDomains(header));
		},
		async getVerifiersForSurveyRecurrenceRules ({ commit }) {
			commit('GET_VERIFIERS_SURVEY_RECURRENCE_RULES', await VerifiersRepository.getVerifiersForSurveyRecurrenceRules());
		},
		async createVerifire ({ commit }, body) {
			return await VerifiersRepository.createVerifire(body);
		},
		async editVerifire ({ commit }, body) {
			return await VerifiersRepository.editVerifire(body);
		},
		async getVerifiers ({ commit }, header) {
			commit('GET_ALL_VERIFIRES', await VerifiersRepository.getVerifiers(header));
		},
		async getTimeZones ({ commit }) {
			commit('GET_TIME_ZONES', await VerifiersRepository.getTimeZones());
		},
	},
	getters: {},
};
