import { UsersError } from '@/lib/Errors';
import _axios from '@/plugins/axios';
export default {
	async getAppointments () {
		const response = await _axios.get('appointments');
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async getAppointmentsForToday (id) {
		const response = await _axios.get('appointments/appointmentsfordoctorfortoday', { headers: { doctorId: id } });
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async getAppointmentsForDoctor (id) {
		const response = await _axios.get('appointments/appointmentsfordoctor', { headers: { doctorId: id } });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new UsersError('No meta data!');
	},
	async getAppointmentsByHospitalId (id) {
		const response = await _axios.get('appointments/appointmentsforhospitalfortoday', { headers: { hospitalId: id } });
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async createAppointment (body) {
		const response = await _axios.post('appointments', body);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async updateAppointments (body) {
		const response = await _axios.put(`appointments/${body.appointmentId}`, body);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async newAppointment (body) {
		const response = await _axios.post('appointments/createappointment', body);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async addAppointmentNote (body) {
		const response = await _axios.post('appointments/addnote', body);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async getNotesWithPatient (patientId) {
		const response = await _axios.get('appointments/getnotesforpatientid', { headers: { patientId: patientId } });
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async startAppointment (body) {
		const response = await _axios.put('appointments/startvideocall', body);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async stopAppointment (body) {
		const response = await _axios.put('appointments/stopvideocall', body);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async addAttendeeForAppointment (body) {
		const response = await _axios.post('appointments/addattendeeforappointment', body);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
};
