import Repository from '../../repositories/RepositoryFactory';
import { setAuthToken } from '@/plugins/axios';

const libphonenumber = require('libphonenumber-js');
const AuthenticationRepository = Repository.get('authentication');

export default {
	namespaced: true,
	state: {
		sessionToken: null,
		roleName: null,
		userData: null,
		branchOfficeData: null,
		hospitalId: null,
		myMedicalTeams: [],
		userSettingsJson: '',
		filterCase: 'TODAY',
		delay: 3000,
		specialization: null,
	},
	mutations: {
		DO_LOGIN (state, payload) {
			state.sessionToken = payload.sessionObject.sessionGuid;
			if (payload.userObject.phone.includes('+')) {
				payload.userObject.phone = libphonenumber.parsePhoneNumberFromString(payload.userObject.phone).formatInternational();
			}
			state.userData = payload.userObject;
			state.specialization = payload.userObject;
			state.hospitalId = payload.userObject?.hospitalAssignedData?.id;
			localStorage.sessionToken = payload.sessionObject.sessionGuid;
			setAuthToken(payload.sessionObject.sessionGuid);
			state.roleName = payload.userObject.roleData.roleName;
			state.userSettingsJson = payload.userObject.userSettingsJson;
			payload.userObject.medicalTeamsData.forEach(mt => state.myMedicalTeams.push(mt.id));

			if (localStorage.getItem('selectedHospitalAssignedId')) {
				const selectedHospitalAssignedId = JSON.parse(localStorage.getItem('selectedHospitalAssignedId'));
				if (payload.userObject?.hospitalsAssignedData != null) {
					state.userData.hospitalAssignedData = payload.userObject?.hospitalsAssignedData?.filter(h => h.id === selectedHospitalAssignedId)[0]; // get the first element
				}
			} else {
				// localStorage.setItem('selectedHospitalAssignedId', JSON.stringify(payload.userObject?.hospitalAssignedData?.id));
				const role = payload.userObject.roleData.roleName;
				if (role === 'HOSPITAL_ADMIN' || role === 'DOCTOR' || role === 'NURSE' || role === 'CARE_GIVER' || role === 'PATIENT') {
					localStorage.setItem('selectedHospitalAssignedId', JSON.stringify(payload.userObject?.hospitalAssignedData?.id));
				} else {
					localStorage.setItem('selectedHospitalAssignedId', '-1');
				}
			}
		},
		LOGOUT (state) {
			localStorage.clear();
			state.sessionToken = null;
			state.myMedicalTeams = [];
			setAuthToken(null);
		},
		CHANGE_PREFEENCE (state, payload) {
			state.userSettingsJson = payload.userSettingsJson;
		},
		initialize (state) {
			// TODO: In the future, we can load all stored state from
			//       localStorage with https://www.mikestreety.co.uk/blog/vue-js-using-localstorage-with-the-vuex-store

			state.sessionToken = localStorage.sessionToken;
			setAuthToken(state.sessionToken);
		},
		CHANGE_FILTER_CASE (state, payload) {
			state.filterCase = payload;
		},
		SET_SELECTED_OFFICE (state, payload) {
			state.userData.hospitalAssignedData = payload;
			localStorage.setItem('selectedHospitalAssignedId', JSON.stringify(payload.id));
		},
	},
	actions: {
		async doLogin ({ commit }, data) {
			commit('DO_LOGIN', await AuthenticationRepository.login(data));
		},
		async doLoginWithToken ({ commit }) {
			commit('DO_LOGIN', await AuthenticationRepository.signinwithtoken());
		},
		async doLogout ({ commit }) {
			commit('LOGOUT', await AuthenticationRepository.logout());
		},
		async changePrefenrences ({ commit }, data) {
			commit('CHANGE_PREFEENCE', data);
		},
		async changeFilterCase ({ commit }, data) {
			commit('CHANGE_FILTER_CASE', data);
		},
	},
	getters: {
		getUserObject (state) {
			return state;
		},
		hasStoredSession (state) {
			return (!!state.sessionToken);
		},
		getRole (state) {
			return state.roleName;
		},
		getFullName (state) {
			return state.userData?.firstName + ' ' + state.userData?.lastName;
		},
		getUserSettings (state) {
			const a = JSON.parse(state.userSettingsJson);
			return a;
		},
		getHospitalId (state) {
			return state.hospitalId;
		},
		getMyMedicalTeam (state) {
			return state.myMedicalTeams;
		},
		getDelay (state) {
			return state.delay;
		},
		getSpecialization (state) {
			return state.specialization;
		},
	},
};
