import { UsersError } from '@/lib/Errors';
import _axios from '@/plugins/axios';
import PatientMetaData from '../../models/PatientMetaData';
export default {
	async getMetaData (id) {
		const response = await _axios.get('patientmetadata/getmetadataforpatient', { headers: { patientId: id } });
		if (response.data.resFlag) {
			return new PatientMetaData(response.data.data);
		}
		throw new UsersError('No meta data!');
	},
	async createPatientMetaData (body) {
		const response = await _axios.post('patientmetadata', body);
		if (response.data.resFlag) {
			return new PatientMetaData(response.data.data);
		}
		throw new UsersError('No meta data!');
	},
	async editPatientMetaData (body) {
		const response = await _axios.put('patientmetadata/updatemetadataforpatient', body);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('Could not update the data!');
	},
	async getDiagnosesByCategory (body) {
		const response = await _axios.get('verifier/getdiagnosesbycategorycode', { headers: { diagnosisCategoryCode: body } });
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('Could not get the diagnosis data!');
	},
};
