import Error from '@/lib/extendable-error';

export class APIServerError extends Error {
	constructor (message, response) {
		super(message);
		this.response = response;
	}
}

export class AuthenticationError extends Error { }
export class UsersError extends Error { }
export class CountriesError extends Error { }
export class VerifiersError extends Error { }
export class BillingsError extends Error { }
export class TimeTrackingError extends Error { }
export class InvoicesError extends Error { }
