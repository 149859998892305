import { VerifiersError } from '@/lib/Errors';
import _axios from '@/plugins/axios';

export default {
	async dinstinctDomains (header) {
		const response = await _axios.get('verifier/dinstinctDomains', { headers: header });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new VerifiersError('No meta data!');
	},
	async getVerifiers (header) {
		const response = await _axios.get('verifier/getallverifiers', { headers: header });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new VerifiersError('No meta data!');
	},
	async getVerifiersForDomain (header) {
		const response = await _axios.get('verifier/getverifiersfordomain', { headers: header });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new VerifiersError('No meta data!');
	},
	async getVerifiersForMultipleDomains (header) {
		const response = await _axios.get('verifier/getverifiersformultipledomains', { headers: header });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new VerifiersError('No meta data!');
	},
	async getVerifiersForSurveyRecurrenceRules () {
		const header = { domainsList: 'SURVEY_RECURRENCER_RULE' };
		const response = await _axios.get('verifier/getverifiersformultipledomains', { headers: header });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new VerifiersError('No meta data!');
	},
	async createVerifire (body) {
		return await _axios.post('verifier', body);
	},
	async editVerifire (body) {
		return await _axios.put(`verifier/${body.id}`, body);
	},
	async getTimeZones () {
		const response = await _axios.get('verifier/gettimezones');
		if (response.status === 200) {
			return response.data.data;
		}
		throw new VerifiersError('No meta data!');
	},
};
