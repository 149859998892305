import { AuthenticationError } from '@/lib/Errors';
import _axios from '@/plugins/axios';
import Authentication from '../../models/Authentication';

export default {
	async checkAuth () {
		const response = await _axios.post('/auth/signinwithtoken');
		if (response.data.resFlag) {
			return true;
		}

		throw new AuthenticationError('Invalid username or password');
	},
	async login (data) {
		const response = await _axios.post('/auth/signin', data);
		if (response.data.resFlag) {
			if (response.data.data.userObject.roleId === 5 || response.data.data.userObject.roleId === 7) {
				throw new AuthenticationError('You cannot authenticate as a Patient or Caregiver!');
			}
			const userJson = new Authentication(response.data.data);
			return userJson;
		} else {
			throw new AuthenticationError('Invalid username or password');
		}
	},
	async logout () {
		await _axios.post('/auth/signout');
	},
	async signinwithtoken () {
		const response = await _axios.post('/auth/signinwithtoken');
		if (response.data.resFlag) {
			if (response.data.data.userObject.roleId === 5) {
				throw new AuthenticationError('You cannot authenticate as a Patient!');
			}
			const userJson = new Authentication(response.data.data);
			return userJson;
		}
		throw new AuthenticationError('Error');
	},
};
