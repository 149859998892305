import _axios from '@/plugins/axios';
import { BillingsError } from '../../../lib/Errors';

export default {
	async getbillingsummaryforallpatientsformonth (object) {
		// const response = await _axios.get('billing/getbillingsummaryforallpatientsformonth', { headers: object });
		const response = await _axios.get('billing/getbillingsummaryforallpatientsformonthv2', { headers: object });
		if (response.data.resFlag) {
			return response.data?.data?.billingHeaderSummaryData;
		}
		throw new BillingsError('No summary!');
	},
	async getbillingsummaryforpatientformonth (object) {
		const response = await _axios.get('billing/getbillingsummaryforpatientformonth', { headers: object });
		if (response.data.resFlag) {
			return response.data;
		}
		throw new BillingsError('No summary!');
	},
	async getbillingdetailsforpatientformonth (object) {
		const response = await _axios.get('billing/getbillingdetailsforpatientformonth', { headers: object });
		if (response.data.resFlag) {
			return response.data?.data;
		}
		throw new BillingsError('No summary!');
	},
	async generateforallhospitalpatientsformonth (object) {
		const response = await _axios.get('billing/generateforallhospitalpatientsformonth', { headers: object });
		if (response.data.resFlag) {
			return response.data?.data;
		}
		throw new BillingsError('No summary!');
	},
	async generateforpatientformonth (object) {
		const response = await _axios.get('billing/generateforpatientformonth', { headers: object });
		if (response.data.resFlag) {
			return response.data?.data;
		}
		throw new BillingsError('No summary!');
	},
	async generatedPdfFile (object) {
		// const response = await _axios.get('billing/getbillingprogressaspdf', { headers: object });
		// console.log(response);
		// if (response.data) {
		// 	return response.data.data;
		// }
		const response = await _axios({
			url: 'billing/getbillingprogressaspdf',
			method: 'GET',
			responseType: 'blob',
			headers: {
				organizationId: object.organizationId,
				month: object.month,
				year: object.year,
				officeIds: object.officeIds,
			},
		});
		if (response.status === 200) {
			return response;
		}
		throw new BillingsError('No summary!');
	},
	async generatePdfForAllHospitals (object) {
		// const response = await _axios.get('billing/getbillingprogressaspdf', { headers: object });
		// console.log(response);
		// if (response.data) {
		// 	return response.data.data;
		// }
		const response = await _axios({
			url: 'billing/getbillingprogressaspdf',
			method: 'GET',
			responseType: 'blob',
			headers: {
				organizationId: object.organizationId,
				month: object.month,
				year: object.year,
				officeIds: [],
			},
		});
		if (response.status === 200) {
			return response;
		}
		throw new BillingsError('No summary!');
	},
	async generateforallorganizationpatientsformonth (object) {
		const response = await _axios.get('billing/generateforallorganizationpatientsformonth', { headers: object });
		if (response.data.resFlag) {
			return response.data?.data;
		}
		throw new BillingsError('No summary!');
	},
	async generateCsvOrganizationPatientsForMonth (object) {
		const response = await _axios({
			url: 'billing/generatecsvorganizationpatientsformonth',
			method: 'GET',
			responseType: 'blob',
			headers: {
				organizationId: object.organizationId,
				month: object.month,
				year: object.year,
			},
		});
		if (response.status === 200) {
			return response;
		}
		throw new BillingsError('No summary!');
	},
	async generateCsvHospitalPatientsForMonth (object) {
		const response = await _axios({
			url: 'billing/generatecsvhospitalpatientsformonth',
			method: 'GET',
			responseType: 'blob',
			headers: {
				hospitalId: object.hospitalId,
				month: object.month,
				year: object.year,
			},
		});
		if (response.status === 200) {
			return response;
		}
		throw new BillingsError('No summary!');
	},
};
